import Homepage from "./homepage/homepage";
import TopMenu from "./topMenu/topMenu";

import "./app.css";

export default function App() {
  return (
    <div>
      <div>
        <TopMenu />
      </div>
      <div>
        <Homepage />
      </div>
    </div>
  );
}
